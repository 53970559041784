import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | АвтоПрокат Плюс - Зручна оренда автомобілів для ваших потреб
			</title>
			<meta name={"description"} content={"Ласкаво просимо до АвтоПрокат Плюс! Пропонуємо зручний сервіс оренди автомобілів для подорожей і бізнесу. Великий вибір авто, доступні ціни та якісне обслуговування."} />
			<meta property={"og:title"} content={"Головна | АвтоПрокат Плюс - Зручна оренда автомобілів для ваших потреб"} />
			<meta property={"og:description"} content={"Ласкаво просимо до АвтоПрокат Плюс! Пропонуємо зручний сервіс оренди автомобілів для подорожей і бізнесу. Великий вибір авто, доступні ціни та якісне обслуговування."} />
			<meta property={"og:image"} content={"https://silverleaft.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://silverleaft.com/img/6969347.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://silverleaft.com/img/6969347.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://silverleaft.com/img/6969347.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://silverleaft.com/img/6969347.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://silverleaft.com/img/6969347.png"} />
			
			
		</Helmet>
		<Components.Header />
		<Section padding="180px 0 384px 0" background="linear-gradient(180deg,rgba(11, 15, 59, 0.81) 0%,rgba(11, 15, 59, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://silverleaft.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box" sm-padding="120px 0 324px 0" quarkly-title="Hero-15">
			<Text
				margin="0px 0px 50px 0px"
				text-align="center"
				font="--headline1"
				letter-spacing="1px"
				color="#6d32ec"
				padding="0px 150px 0px 150px"
				lg-padding="0px 0 0px 0"
				text-transform="uppercase"
			>
				АвтоПрокат Плюс
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				color="#c8ced8"
				text-align="center"
				padding="0px 50px 0px 50px"
				font="--lead"
				lg-padding="0px 0 0px 0"
			>
				Ласкаво просимо до АвтоПрокат Плюс – Зручна оренда автомобілів!<br/> Ми пропонуємо зручний сервіс оренди автомобілів для ваших подорожей і бізнесових потреб.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="rgba(0, 0, 0, 0) url(https://silverleaft.com/img/2.jpg) 0% 0% /cover no-repeat scroll padding-box"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
				color="--light"
			>
				<Box
					width="60%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--light"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Широкий вибір автомобілів
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
					У нашому автопарку ви знайдете автомобілі на будь-який смак та бюджет. Від компактних міських автомобілів до розкішних седанів та потужних позашляховиків – ми пропонуємо тільки найкращі моделі від провідних виробників. Незалежно від того, чи вам потрібен автомобіль для бізнес-поїздок, сімейних подорожей або романтичних вихідних, у нас завжди знайдеться підходящий варіант.
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Простий та швидкий процес бронювання
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Прозорі умови оренди без прихованих платежів
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Нові та добре обслуговувані автомобілі
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Підтримка клієнтів 24/7
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Гнучкі умови оренди
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Ми пропонуємо гнучкі умови оренди, що дозволяють вам вибрати найзручніший варіант. Ви можете орендувати авто на короткий або довгий термін, а також скористатися нашими спеціальними пропозиціями та знижками.
				</Text>
			</Box>
			<Box
				display="grid"
				width="100%"
				margin="80px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				sm-grid-template-columns="1fr"
			>
				<Image
					src="https://silverleaft.com/img/3.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="1 / span 1"
					border-radius="24px"
				/>
				<Image
					src="https://silverleaft.com/img/4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="2 / span 2"
					border-radius="24px"
					sm-grid-column="1 / span 1"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});